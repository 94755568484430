<template>
  <div>
    <navbar />
    <div class="flex justify-center items-center my-4 flex-col">
         <img class="h-40 sm:h-96" :src="`${$apiDevuelvoya}/images/shared/void.svg`" >
         <div class="my-4">
            <span class="text-xl text-blue-500"> !Que raro! Al parecer no puedes acceder a esta sección</span>
         </div>
         <div class="my-4">
           <div @click="back()" class="bg-blue-500 text-white p-2 rounded "> Volver</div>
         </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from '@/components/Core/Navbar/Navbar.vue';

export default {
  components: { Navbar },
  computed: {
    ...mapState('auth', ['token']),
  },
  data() {
    return {
      route_from_path: '',
    };
  },
  methods: {
    back() {
      if (!this.token) {
        this.$router.push({ name: 'Login' });
      } else if (this.route_from_path) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: 'Login' });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
    /* eslint-disable no-param-reassign */
      vm.route_from_path = from.name;
    });
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

</style>
